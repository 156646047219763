<template>
    <el-image   :preview-src-list="imgs"
                style="width: 50px; height: 50px;"
                :src="imgs[0]"
                fit="fill">
        <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline" style="font-size: 50px;color:#999"></i>
        </div>
    </el-image>
</template>

<script>
import {fileUrl} from "@/api/Global";
export default {
    name: "CustomImg",
    props:{
        list:{
            type:[String,Array,Object],
            default:()=>null
        }
    },
    computed:{
        fileUrl(){
            return fileUrl
        },
        imgs(){
            let arr = []
            if(this.list&&this.list.properties){
                arr = this.list.properties;
                arr = arr.split(",")
            }
            arr.forEach((item,index)=>{
                arr[index] = this.fileUrl + item
            })
            return arr;
        }
    },
}
</script>

<style scoped>

</style>