<template>
    <div>
<!--        <vxe-table size="mini"  height="400" ref="table" border resizable-->
<!--                   :auto-resize="true"-->
<!--                   :header-row-style="headerStyle"-->
<!--                   :row-style="rowStyleFn"-->
<!--                   align="center" class="vxe-table-element"  :data="records">-->
<!--            <vxe-table-column field="time" title="时间" width="160" />-->
<!--            <vxe-table-column field="courseName" title="培训课程" />-->
<!--            <vxe-table-column field="completeRatio" title="完成率(%)" />-->
<!--        </vxe-table>-->

        <el-table size="mini" border  :data="records" highlight-current-row height="400" style="width: 100%">
            <el-table-column align="center" type="index" label="序号" width="50"/>
            <el-table-column type="expand">
                <template slot-scope="props">
                    <el-table size="mini" border :data="props.row.userCourseVideoVos" style="width: 100%">
                        <el-table-column label="视频标题" prop="videoTitle" />
                        <el-table-column label="总分数" prop="totalScore" />
                        <el-table-column label="考试分数" prop="testScores" />
                        <el-table-column label="通过分数" prop="passingScore" />
                        <el-table-column label="视频观看进度">
                            <template v-slot="scope">
                                <div>
                                    {{
                                        scope.row.videoSchedule
                                    }}%
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="考试通过情况">
                            <template v-slot="scope">
                                <div style="color: #71a100" v-if="scope.row.testStatus === 0">
                                    {{
                                        scope.row.testStatusStr
                                    }}
                                </div>
                                <div style="color: #ff0000" v-if="scope.row.testStatus !== 0">
                                    {{
                                        scope.row.testStatusStr
                                    }}
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </template>
            </el-table-column>
            <el-table-column label="课程名称" prop="courseName" />
            <el-table-column label="生效时间" prop="startTime" />
            <el-table-column label="失效时间" prop="endTime" />
            <el-table-column label="课程学习进度">
                <template v-slot="scope">
                    <div>{{ scope.row.courseSchedule }}%</div>
                </template>
            </el-table-column>
        </el-table>

        <div style="height: 20px" />
        <el-pagination background :current-page="ajaxParams.currentPage" :page-sizes="[5, 10, 15, 20, 50, 100]"
                       :page-size="ajaxParams.pageSize" layout="total, ->, sizes, prev, pager, next, jumper" :total="total"
                       @size-change="(pageSize)=>{ajaxParams.pageSize=pageSize;queryList()}"
                       @current-change="(currentPage)=>{ajaxParams.currentPage=currentPage;queryList()}" />
    </div>
</template>

<script>
export default {
    //纠偏学习
    name: "RectifyStudy",
    props: {
        row:{
            type: Object,
            default: () => {}
        }
    },
    data(){
        return{
            ajaxParams:{
                currentPage:1,
                pageSize:20,
                userId:null,
                // property:null,   //
                // direction:null,  //
            },
            records:[],
            total:0,
        }
    },
    created() {
        this.queryList();
    },
    methods:{
        async queryList(){
            this.ajaxParams.userId = this.row.id;
            let {code,data} = await this.$api.get(
                "/course/server/escort/web/courseUser/details",
                // `/archives/server/escort/web/archives/course/${this.row.id}`,
                {
                    params:this.ajaxParams
                });
            if(code===200){
                this.records = data.pageVo.content;
                this.total = ~~data.pageVo.totalElements;
            }
        }
    }
}
</script>

<style scoped>

</style>