<template>
<div>
    <vxe-table size="mini"  height="400" ref="table" border resizable
               :auto-resize="true"
               :header-row-style="headerStyle"
               :row-style="rowStyleFn"
               align="center" class="vxe-table-element"  :data="records">
        <vxe-table-column type="seq" title="序号" width="50"/>
        <vxe-table-column field="alarmDateTime" title="报警时间" />
        <vxe-table-column field="carNum" title="车牌号" />
        <vxe-table-column field="alarmEvent" title="报警事件" />
        <vxe-table-column field="speedDesc" title="行车车速" />
    </vxe-table>

    <div style="height: 20px" />
    <el-pagination background :current-page="ajaxParams.currentPage" :page-sizes="[5, 10, 15, 20, 50, 100]"
                   :page-size="ajaxParams.pageSize" layout="total, ->, sizes, prev, pager, next, jumper" :total="total"
                   @size-change="(pageSize)=>{ajaxParams.pageSize=pageSize;queryList()}"
                   @current-change="(currentPage)=>{ajaxParams.currentPage=currentPage;queryList()}" />
</div>
</template>

<script>
export default {
    //主动报警
    name: "AutoAlarms",
    props: {
        row:{
            type: Object,
            default: () => {}
        }
    },
    data(){
        return{
            ajaxParams:{
                currentPage:1,
                pageSize:20,
                // property:null,   //
                // direction:null,  //
                userId:null,
            },
            records:[],
            total:0,
        }
    },
    created() {
        this.queryList();
    },
    methods:{
        async queryList(){
            this.ajaxParams.userId = this.row.id;
            let {code,data} = await this.$api.get("/adapter/server/escort/web/drivingBehaviorAlarm/pageByUserId",{
                params:this.ajaxParams
            });
            if(code===200){
                this.records = data.content;
                this.total = ~~data.totalElements;
            }
        }
    }
}
</script>

<style scoped>

</style>