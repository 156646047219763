<template>
  <el-dialog
    :visible.sync="show"
    width="30%"
    :append-to-body="true"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    @close="close"
  >
    <div slot="title" class="header-title">
      <el-row :gutter="5">
        <el-col :span="24">
          <span class="title-name">{{ info.title }}</span>
        </el-col>
      </el-row>
    </div>
    <el-row>
      <el-col :span="24">
        <el-form ref="ruleForm" label-width="110px" class="allForm">
          <el-form-item label="角色名称" :required="true">
            <el-select v-model="form.roleIds" filterable multiple class="formInput w-100">
              <el-option v-for="(v,i) in roleList" :key="v.id" :value="v.id" :label="v.roleName" />
            </el-select>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <div slot="footer" class="dialog-footer" style="text-align: center;">
      <el-button type="primary" @click="commit">提交</el-button>
      <el-button type="info" @click="close">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { roleUserDrop, roleUserEcho, userRole } from '@/api'
export default {
  name: 'DispatchMenu',
  props: {
    // 打开dialog的
    dialog: {
      type: Boolean, // 指定传入的类型
      // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
      default: false // 这样可以指定默认的值
    },
    info: {
      type: Object, // 指定传入的类型
      // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
      default: () => {} // 这样可以指定默认的值
    },
    row: {
      type: Object, // 指定传入的类型
      // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
      default: () => {} // 这样可以指定默认的值
    },
    company: {
      type: Object, // 指定传入的类型
      // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
      default: () => {} // 这样可以指定默认的值
    }
  },
  data() {
    return {
      show: this.dialog,
      roleList: [],
      form: {
        roleIds: []
      },
      roleIds: []
    }
  },
  created() {
  },
  mounted() {
    this.getRole()
    // setTimeout(this.getUser, 300)
  },
  methods: {
    getRole() {
      this.$axiosReq(roleUserDrop, null, {
        companyId: this.row.companyId
      }, 'get').then(res => {
        this.roleList = res.data
		this.getUser();
      })
    },
    getUser() {
      this.$axiosReq(roleUserEcho, null, {
        userId: this.row.id
      }, 'get').then(res => {
        if(res.data){
          for(const i of res.data){
            // this.form.roleIds.push(i.id)
				for(const w of this.roleList){
					if (i.id == w.id) {
						this.form.roleIds.push(i.id)
					}
				}
          }
        }
      })
    },
    removeRole(v) {
      if (!this.$admin && v.isDefault == 0) {
        return false
      }
    },
    // 提交添加
    commit() {
      if (this.form.roleIds.length === 0) {
        this.$message.error('请选择角色！')
        return
      } else {
        this.roleIds = this.form.roleIds.filter(v => {
          return v
        })
      }
      this.$axiosReq(userRole, {
        roleIds: this.roleIds,
        userId: this.row.id
      }, null, 'put').then(res => {
        this.$message.success(res.msg)
        this.close()
      })
    },
    // 关闭模态框
    close() {
      this.show = false
      this.$emit('refresh')
    }
  }
}
</script>

<style scoped>

</style>
