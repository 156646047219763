<template>
    <el-dialog
        :visible.sync="show"
        width="40%"
        :append-to-body="true"
        :destroy-on-close="true"
        :close-on-click-modal="false"
        @close="close"
    >
        <div slot="title" class="header-title">
            <el-row :gutter="5">
                <el-col :span="24">
                    <span class="title-name">{{ info.title }}</span>
                </el-col>
            </el-row>
        </div>
        <el-row :gutter="0">
            <el-col :span="24">
                <el-form ref="ruleForm" label-width="150px" class="allForm">
                    <el-form-item label="真实姓名" :required="true">
                        <el-input v-model="form.realName" class="formInput" />
                    </el-form-item>
                    <el-form-item
                        label="性别"
                        :required="true"
                        class="formInput"
                    >
                        <el-select v-model="form.sex" class="w-100">
                            <el-option
                                v-for="(v, i) in sex"
                                :key="v.id"
                                :value="v.dictCode"
                                :label="v.dictName"
                            />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="手机号" :required="true">
                        <el-input
                            v-model="form.phone"
                            class="formInput"
                            maxlength="11"
                            @input="verifyPhone"
                        />
                    </el-form-item>
                    <el-form-item
                        v-if="$admin"
                        label="公司"
                        :required="true"
                        class="formInput"
                    >
                        <el-select
                            v-model="form.companyId"
                            class="w-100"
                            filterable
                            @change="changeCompany"
                        >
                            <el-option
                                v-for="(v, i) in company"
                                :key="v.id"
                                :value="v.id"
                                :label="v.companyName"
                            />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="人员IC卡号">
                        <el-input v-model="form.icCard" class="formInput" />
                    </el-form-item>
                    <el-form-item label="用户账号">
                        <el-input v-model="form.username" class="formInput" />
                    </el-form-item>
                    <el-form-item label="邮箱">
                        <el-input v-model="form.email" class="formInput" />
                    </el-form-item>
                    <el-form-item label="文化程度">
                        <!-- <el-input v-model="form.qualifications" class="formInput" /> -->
                        <el-select v-model="form.qualifications" class="w-100">
                            <el-option
                                v-for="(v, i) in qualifications"
                                :key="v.id"
                                :value="v.dictCode"
                                :label="v.dictName"
                            />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="身份证号">
                        <el-input
                            v-model="form.idCard"
                            class="formInput"
                            maxlength="18"
                            @input="verifyId"
                        />
                    </el-form-item>
                    <el-form-item label="是否是部门管理员" class="formInput">
                        <el-select v-model="form.isDepartManager" class="w-100">
                            <el-option
                                v-for="(v, i) in isDepartManager"
                                :key="i"
                                :value="v.id"
                                :label="v.name"
                            />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="所属部门" class="formInput">
                        <el-cascader
                            ref="dept"
                            v-model="form.departId"
                            :options="depart"
                            class="w-100"
                            :props="{
                                expandTrigger: 'hover',
                                value: 'id',
                                children: 'sons',
                                label: 'departName',
                                emitPath: false,
                                checkStrictly: true,
                            }"
                            filterable
                            clearable
                        />
                    </el-form-item>
                    <el-form-item label="岗位" class="formInput">
                        <el-select
                            v-model="form.positionId"
                            class="w-100"
                            filterable
                        >
                            <el-option
                                v-for="(v, i) in position"
                                :key="v.id"
                                :value="v.id"
                                :label="v.positionName"
                            />
                        </el-select>
                    </el-form-item>
                    <el-form-item
                        v-if="industry == 4"
                        label="线路"
                        class="formInput"
                    >
                        <el-select
                            v-model="form.lineId"
                            class="w-100"
                            filterable
                        >
                            <el-option
                                v-for="(v, i) in lineList"
                                :key="v.id"
                                :value="v.id"
                                :label="v.lineName"
                            />
                        </el-select>
                    </el-form-item>
                    <el-form-item
                        v-if="industry === '0'"
                        label="车牌号"
                        class="formInput"
                    >
                        <el-select
                            v-model="form.carIds"
                            class="w-100"
                            filterable
                            multiple
                        >
                            <el-option
                                v-for="(v, i) in carList"
                                :key="i"
                                :value="v.carId"
                                :label="v.carNum"
                            />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="标签" class="formInput">
                        <el-select
                            v-model="form.labelIds"
                            class="w-100"
                            filterable
                            multiple
                        >
                            <el-option
                                v-for="(v, i) in labelList"
                                :key="v.id"
                                :value="v.id"
                                :label="v.labelName"
                            />
                        </el-select>
                    </el-form-item>
                    <el-form-item
                        label="是否参加学习"
                        :required="true"
                        class="formInput"
                    >
                        <el-select
                            v-model="form.isRelatedCourses"
                            class="w-100"
                        >
                            <el-option
                                v-for="(v, i) in isCourse"
                                :key="i"
                                :value="v.dictCode"
                                :label="v.dictName"
                            />
                        </el-select>
                    </el-form-item>
                    <el-form-item
                        label="是否参加状态测评"
                        :required="true"
                        class="formInput"
                    >
                        <el-select v-model="form.isAppraisal" class="w-100">
                            <el-option
                                v-for="(v, i) in isAppraisalNum"
                                :key="i"
                                :value="v.id"
                                :label="v.name"
                            />
                        </el-select>
                    </el-form-item>
                    <!-- <el-form-item label="当月是否生效" :required="true" class="formInput" v-if="info.addOr == 'add'">
						<el-select v-model="form.isTheCurrentMonthRelated" class="w-100">
							<el-option v-for="(v, i) in isRelatedNum" :key="i" :value="v.id" :label="v.name" />
						</el-select>
					</el-form-item> -->
                </el-form>
            </el-col>
        </el-row>
        <div slot="footer" class="dialog-footer" style="text-align: center">
            <el-button type="primary" @click="commit">提交</el-button>
            <el-button type="info" @click="close">取消</el-button>
        </div>
    </el-dialog>
</template>

<script>
import { textIdCard, textTel, textCar } from "@/utils/validated";
import {
    user,
    company,
    dropUserPosition,
    getDictParentCode,
    depart,
    userVerifyPhone,
    userVerifyEmail,
    userVerifyCard,
} from "@/api";

export default {
    name: "Operate",
    props: {
        // 打开dialog的
        dialog: {
            type: Boolean, // 指定传入的类型
            // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
            default: false, // 这样可以指定默认的值
        },
        info: {
            type: Object, // 指定传入的类型
            // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
            default: () => {}, // 这样可以指定默认的值
        },
        row: {
            type: Object, // 指定传入的类型
            // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
            default: () => {}, // 这样可以指定默认的值
        },
    },
    data() {
        return {
            show: this.dialog,
            form: {
                companyId: this.$admin ? "" : this.$companyId,
                departId: "",
                email: "",
                idCard: "",
                isRelatedCourses: "1",
                password: "",
                phone: "",
                positionId: "",
                realName: "",
                sex: "",
                username: "",
                isTheCurrentMonthRelated: 0,
                isAppraisal: 0,
                qualifications: "",
                isDepartManager: 1,
                lineId: "",
                icCard: "",
                carIds: [],
                labelIds: [],
            },
            qualifications: [],
            isDepartManager: [
                {
                    name: "是",
                    id: 0,
                },
                {
                    name: "否",
                    id: 1,
                },
            ],
            isRelatedNum: [
                {
                    name: "是",
                    id: 0,
                },
                {
                    name: "否",
                    id: 1,
                },
            ],
            isAppraisalNum: [
                {
                    name: "是",
                    id: 0,
                },
                {
                    name: "否",
                    id: 1,
                },
            ],
            sex: [],
            company: [],
            job: [],
            position: [],
            depart: [],
            isCourse: [],
            lineList: [],
            industry: "",
            carList: [],
            labelList: [],
        };
    },
    created() {
        this.getDictParentCode();
        this.getCompany();
    },
    mounted() {
        this.$nextTick(() => {
            this.upd();
        });
        if (!this.$admin) {
            this.industry = JSON.parse(
                sessionStorage.UserDetail
            ).sysCompanyVo.industry;
            this.getDepart();
            this.getPosition();
            this.getLine();
            this.getCar();
            this.getLabel();
        }
    },
    methods: {
        upd() {
            if (this.info.addOr === "upd") {
                this.$axiosReq(user + this.row.id, null, null, "get").then(
                    (res) => {
                        // this.form = res.data;
                        this.form.companyId = res.data.companyId
                            ? res.data.companyId
                            : "";
                        this.form.positionName = res.data.positionName
                            ? res.data.positionName
                            : "";
                        this.form.departName = res.data.departName
                            ? res.data.departName
                            : "";
                        this.form.departId = res.data.departId
                            ? res.data.departId
                            : "";
                        this.form.positionId = res.data.positionId
                            ? res.data.positionId
                            : "";
                        this.form.email = res.data.email ? res.data.email : "";
                        this.form.idCard = res.data.idCard
                            ? res.data.idCard
                            : "";
                        this.form.password = res.data.password
                            ? res.data.password
                            : "";
                        this.form.phone = res.data.phone ? res.data.phone : "";
                        this.form.realName = res.data.realName
                            ? res.data.realName
                            : "";
                        this.form.username = res.data.username
                            ? res.data.username
                            : "";
                        this.form.isTheCurrentMonthRelated = res.data
                            .isTheCurrentMonthRelated
                            ? res.data.isTheCurrentMonthRelated
                            : 0;
                        this.form.qualifications = res.data.qualifications
                            ? res.data.qualifications
                            : "";
                        this.form.isDepartManager = res.data.isDepartManager
                            ? res.data.isDepartManager
                            : "";
                        this.form.isRelatedCourses = res.data.isRelatedCourses
                            ? res.data.isRelatedCourses
                            : "0";
                        this.form.isRelatedCourses =
                            this.form.isRelatedCourses + "";
                        this.form.sex = res.data.sex + "";
                        this.form.lineId = res.data.lineId
                            ? res.data.lineId
                            : "";
                        this.form.icCard = res.data.icCard
                            ? res.data.icCard
                            : "";
                        this.industry = res.data.industry;
                        if (!this.form.positionName) {
                            this.form.positionId = "";
                        }
                        if (!this.form.departName) {
                            this.form.departId = "";
                        }
                        if (
                            res.data.isAppraisal === 0 ||
                            res.data.isAppraisal === "0"
                        ) {
                            this.form.isAppraisal = 0;
                        } else {
                            this.form.isAppraisal = 1;
                        }
                        if (
                            res.data.isDepartManager === 0 ||
                            res.data.isAppraisal === "0"
                        ) {
                            this.form.isDepartManager = 0;
                        }
                        if (res.data.labelVos && res.data.labelVos.length > 0) {
                            res.data.labelVos.forEach((item) => {
                                this.form.labelIds.push(item.id);
                            });
                        }
                        if (res.data.carVos && res.data.carVos.length > 0) {
                            res.data.carVos.forEach((item) => {
                                this.form.carIds.push(item.carId);
                            });
                        }
                        this.getDepart(this.form.companyId);
                        this.getPosition(this.form.companyId);
                        this.getLine(this.form.companyId);
                        this.getCar();
                        this.getLabel();
                    }
                );
            }
        },
        isClickRelated() {
            if (this.form.isRelatedCourses == 1) {
                this.form.isTheCurrentMonthRelated = 1;
            } else {
                this.form.isTheCurrentMonthRelated = 0;
            }
        },
        changePositionName(e) {
            let that_ = this;
            this.form.positionId = e;
            this.position.forEach((item) => {
                if (e == item.id) {
                    that_.form.positionName = item.positionName;
                }
            });
            al;
        },
        getDictParentCode() {
            this.$axiosReq(getDictParentCode + "SEX", null, null, "get").then(
                (res) => {
                    this.sex = res.data;
                }
            );
            this.$axiosReq(
                getDictParentCode + "RELEVANCE_TYPE",
                null,
                null,
                "get"
            ).then((res) => {
                this.isCourse = res.data;
            });
            this.$axiosReq(
                getDictParentCode + "EDUCATION",
                null,
                null,
                "get"
            ).then((res) => {
                this.qualifications = res.data;
            });
        },
        getCompany() {
            this.$axiosReq(
                company,
                null,
                {
                    pageSize: 10000,
                },
                "get"
            ).then((res) => {
                this.company = res.data.content;
            });
        },
        getDepart() {
            this.$axiosReq(
                depart,
                null,
                {
                    companyId: this.form.companyId,
                    type: 1,
                    pageSize: 100000,
                },
                "get"
            ).then((res) => {
                this.depart = res.data;
            });
        },
        getLine(v) {
            let that_ = this;
            this.$axiosReq(
                "/car/server/escort/web/line/companyId",
                null,
                {
                    companyId: this.form.companyId,
                },
                "get"
            ).then((res) => {
                this.lineList = res.data;
                var line_one = this.lineList.some(function (item) {
                    if (item.id == that_.form.lineId) {
                        return true;
                    }
                });
                if (!line_one) {
                    that_.form.lineId = "";
                }
            });
        },
        getCar() {
            let that_ = this;
            this.$axiosReq(
                "/car/server/escort/web/car/company/" + this.form.companyId,
                null,
                null,
                "get"
            ).then((res) => {
                this.carList = res.data;
            });
        },
        getLabel() {
            let that_ = this;
            this.$axiosReq(
                "/oauth/server/escort/web/label",
                null,
                {
                    companyId: this.form.companyId,
                    pageSize: 10000,
                },
                "get"
            ).then((res) => {
                this.labelList = res.data.content;
            });
        },
        getPosition(v) {
            let that_ = this;
            this.$axiosReq(
                dropUserPosition,
                null,
                {
                    companyId: this.form.companyId,
                },
                "get"
            ).then((res) => {
                this.position = res.data;
                var result_one = this.position.some(function (item) {
                    if (item.id == that_.form.positionId) {
                        //item.name == "小百里守约"
                        return true; //返回false
                    }
                });
                if (!result_one) {
                    that_.form.positionId = "";
                }
            });
        },
        changeCompany(v) {
            let that_ = this;
            this.form.carIds = [];
            this.form.labelIds = [];
            this.form.lineId = "";
            this.getDepart(v);
            this.getPosition(v);
            this.getLine(v);
            this.getLabel();
            this.company.forEach((item) => {
                if (v == item.id) {
                    that_.industry = item.industry;
                }
            });
            this.getCar();
        },
        verifyEmail(v) {
            // this.$axiosReq(userVerifyEmail,null,{
            //   email:v
            // },'post').then(res=>{
            //
            // })
        },
        verifyPhone() {},
        verifyId() {},
        // 提交添加
        commit() {
            if (this.$textNull(this.form.realName)) {
                this.$message.error("请填写真实名称！");
                return;
            } else if (this.$textNull(this.form.phone)) {
                this.$message.error("请填写电话号码！");
                return;
            } else if (textTel(this.form.phone)) {
                this.$message.error("请填写正确电话号码！");
                return;
            } else if (this.$textNull(this.form.companyId) && this.$admin) {
                this.$message.error("请选择公司！");
                return;
            }
            this.form.isRelatedCourses = this.form.isRelatedCourses + "";
            // this.isClickRelated();
            if (this.info.addOr === "add") {
                this.$axiosReq(user, this.form, null, "post").then((res) => {
                    this.$message.success(res.msg);
                    this.close();
                });
            } else {
                this.$axiosReq(user + this.row.id, this.form, null, "put").then(
                    (res) => {
                        this.$message.success(res.msg);
                        this.close();
                    }
                );
            }
        },
        // 关闭模态框
        close() {
            this.show = false;
            this.$emit("refresh");
        },
    },
};
</script>

<style scoped>
.el-form {
    padding-right: 25px;
}
</style>
