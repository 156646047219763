<template>
	<div class="lookInfo flex-row-box flex-justify-center flex-cont-center" @click="closeBox">
		<div class="lookInfoBox">
			<div class="flex-row-box headerList">
				<div class="exportTitle" />
				<div class="flex-1 text-center">查看课程</div>
				<div class="text-center exportTitle" @click="closeOver">
					<i class="el-icon-error" />
				</div>
			</div>
			<div class="flex-row-box flex-justify-center lookInfoList">
				<div class="lookInfoL">
					<div class="infoImg"><img :src="$fileUrl + headUrl" /></div>
					<div class="flex-row-box flex-cont-center pt-2">
						<div class="infoName">姓名:</div>
						<div>{{ realName }}</div>
					</div>
					<div class="flex-row-box flex-cont-center pt-1">
						<div class="infoName">性别:</div>
						<div>{{ sexName }}</div>
					</div>
					<div class="flex-row-box flex-cont-center pt-1">
						<div class="infoName">身份证号:</div>
						<div>{{ idCard }}</div>
					</div>
					<div class="flex-row-box flex-cont-center pt-1">
						<div class="infoName">手机号:</div>
						<div>{{ phone }}</div>
					</div>
					<div class="flex-row-box flex-cont-center pt-1">
						<div class="infoName">所属地区:</div>
						<div>{{ regionName }}</div>
					</div>
				</div>
				<div class="lookInfoR">
					<el-button type="primary" size="small" @click="getDetails">刷新</el-button>
					<div class="lookInfoTable">
						<el-table border :data="tableData" highlight-current-row height="570" style="width: 100%">
							<el-table-column type="index" />
							<el-table-column type="expand">
								<template slot-scope="props">
									<el-table border :data="props.row.userCourseVideoVos" style="width: 100%">
										<el-table-column label="视频标题" prop="videoTitle" />
										<el-table-column label="总分数" prop="totalScore" />
										<el-table-column label="考试分数" prop="testScores" />
										<el-table-column label="通过分数" prop="passingScore" />
										<el-table-column label="视频观看进度">
											<template slot-scope="scope">
												<div>
													{{
                                                        scope.row.videoSchedule
                                                    }}%
												</div>
											</template>
										</el-table-column>
										<el-table-column label="考试通过情况">
											<template slot-scope="scope">
												<div style="color: #71a100" v-if="scope.row.testStatus === 0">
													{{
                                                        scope.row.testStatusStr
                                                    }}
												</div>
												<div style="color: #ff0000" v-if="scope.row.testStatus !== 0">
													{{
                                                        scope.row.testStatusStr
                                                    }}
												</div>
											</template>
										</el-table-column>
									</el-table>
								</template>
							</el-table-column>
							<el-table-column label="课程名称" prop="courseName" />
							<el-table-column label="生效时间" prop="startTime" />
							<el-table-column label="失效时间" prop="endTime" />
							<el-table-column label="课程学习进度">
								<template slot-scope="scope">
									<div>{{ scope.row.courseSchedule }}%</div>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		courseUserDetails
	} from "@/api";
	export default {
		name: "Info",
		props: {
			row: {
				type: Object, // 指定传入的类型
				default: () => {}, // 这样可以指定默认的值
			},
		},
		data() {
			return {
				show: true,
				tableData: [],
				realName: "",
				phone: "",
				idCard: "",
				regionName: "",
				sexName: "",
			};
		},
		created() {
			//console.log(this.row.id)
			this.getDetails();
		},
		methods: {
			closeBox(e) {
				if (
					e.target.className ===
					"lookInfo flex-row-box flex-justify-center flex-cont-center"
				) {
					this.show = false;
					this.$emit("refresh");
				}
			},
			closeOver() {
				this.show = false;
				this.$emit("refresh");
			},
			getDetails() {
				const that_ = this;
				this.$axiosReq(
					courseUserDetails,
					null, {
						userId: this.row.id,
						pageSize: 10000,
					},
					"get"
				).then((res) => {
					//console.log(JSON.stringify(res))
					that_.realName = res.data.realName;
					that_.sexName = res.data.sexName;
					that_.phone = res.data.phone;
					that_.idCard = res.data.idCard;
					that_.regionName = res.data.regionName;
					that_.headUrl = res.data.headUrl;
					that_.tableData = res.data.pageVo.content;
					this.$message.success("成功");
				});
			},
		},
	};
</script>

<style scoped>
	.lookInfo {
		width: 100%;
		height: 100%;
		position: fixed;
		left: 0;
		top: 0;
		background-color: rgba(0, 0, 0, 0.3);
		z-index: 99;
	}

	.lookInfoBox {
		width: 80%;
		height: 80%;
		background-color: #ffffff;
	}

	.headerList {
		padding: 20px 0;
		border-bottom: 1px solid #c3c3c3;
		color: #5c8bff;
		font-weight: bold;
		font-size: 20px;
	}

	.lookInfoList {
		padding-top: 30px;
		font-size: 16px;
	}

	.lookInfoL {
		width: 20%;
		border-radius: 5px;
		box-shadow: 0 0 8px 8px #f6f9fe;
		padding: 20px;
		box-sizing: border-box;
		max-height: 600px;
		overflow-y: scroll;
		overflow-x: hidden;
		-ms-overflow-style: none;
		overflow: -moz-scrollbars-none;
	}

	.lookInfoL::-webkit-scrollbar {
		display: none;
	}

	.exportTitle {
		width: 50px;
	}

	.infoImg {
		width: 80%;
		margin: 0 auto;
	}

	.infoImg img {
		width: 100%;
	}

	.infoName {
		width: 100px;
		padding-left: 20px;
		box-sizing: border-box;
	}

	.lookInfoR {
		margin-left: 30px;
		width: 68%;
	}

	.lookInfoTable {
		margin-top: 10px;
		height: 570px;
		border: 1px solid #cccccc;
	}

	.demo-table-expand {
		font-size: 0;
	}

	.demo-table-expand label {
		width: 90px;
		color: #99a9bf;
	}

	.demo-table-expand .el-form-item {
		margin-right: 0;
		margin-bottom: 0;
		width: 50%;
	}
</style>
