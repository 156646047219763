<template>
    <div class="healthEarlyWarn">
        <div class="top">

            <el-popover
                    placement="left"
                    width="400"
                    trigger="click">
                <div v-for="(column,index) in columns">
                    <vxe-checkbox
                            v-model="column.visible"
                            :key="index"
                            @change="$refs.xTable.refreshColumn()">

                        <el-tooltip class="item" effect="light" :content="column.title" placement="right">

                            <div style="white-space:nowrap;overflow:hidden;  width: 320px;text-overflow:ellipsis;">{{ column.title }}</div>
                        </el-tooltip>
                    </vxe-checkbox>
                </div>

                <div>
<!--                    <el-button type="text" @click="$refs.xTable.hideColumn($refs.xTable.getTableColumn())">全选</el-button>-->
<!--                    <el-button type="text" @click="$refs.xTable.showColumn($refs.xTable.getTableColumn())">全不选</el-button>-->
                    <div style="height: 10px"></div>
                    <el-button size="mini" type="primary"  @click="$refs.xTable.resetColumn()">重置</el-button>


                </div>

                <el-button title="设置显示的列" slot="reference" type="primary" size="mini" icon="el-icon-s-tools"></el-button>

            </el-popover>


        </div>

        <vxe-table size="mini"  height="380" ref="xTable" border resizable
                   id="healthEarlyWarn"
                   :custom-config="{storage: true}"
                   show-header-overflow="title"
                   :auto-resize="true"
                   :header-row-style="headerStyle"
                   :row-style="rowStyleFn"
                   align="center" class="vxe-table-element"  :data="records">
            <vxe-table-column type="seq" title="序号" width="50"/>
            <vxe-table-column field="reportTime" title="上报时间" />
            <vxe-table-column field="isNormalStr" title="上报状态" />

            <vxe-table-column v-for="(item,index) in fieldTitles" :key="index"
                 :title="item.fieldName"
            >
                <template  v-slot="{ row, rowIndex }">

                    <div v-if="(row.dynamicFormBusinessVos[index]&&row.dynamicFormBusinessVos[index].properties&&/\.(png|jpg)/.test(row.dynamicFormBusinessVos[index].properties))">
                        <CustomImg :list="row.dynamicFormBusinessVos[index]"/>
                    </div>
                    <div v-else>
                        {{row.dynamicFormBusinessVos[index]&&row.dynamicFormBusinessVos[index].properties?row.dynamicFormBusinessVos[index].properties.split("$")[0]:''}}
                    </div>


                </template>
            </vxe-table-column>

<!--            <vxe-table-column field="speedDesc" title="体温" />-->
<!--            <vxe-table-column field="alarmDateTime" title="出车前身体状况" />-->
<!--            <vxe-table-column field="carNum" title="您驾驶区域的风险性" />-->
<!--            <vxe-table-column field="alarmEvent" title="健康码" />-->
<!--            <vxe-table-column field="speedDesc" title="行程码" />-->
        </vxe-table>

        <div style="height: 20px" />
        <el-pagination background :current-page="ajaxParams.currentPage" :page-sizes="[5, 10, 15, 20, 50, 100]"
                       :page-size="ajaxParams.pageSize" layout="total, ->, sizes, prev, pager, next, jumper" :total="total"
                       @size-change="(pageSize)=>{ajaxParams.pageSize=pageSize;queryList()}"
                       @current-change="(currentPage)=>{ajaxParams.currentPage=currentPage;queryList()}" />
    </div>
</template>

<script>
import CustomImg from "./CustomImg.vue"

export default {
    components:{CustomImg},
    //防疫上报
    name: "healthEarlyWarn",
    props: {
        row:{
            type: Object,
            default: () => {}
        }
    },
    data(){
        return{
            ajaxParams:{
                currentPage:1,
                pageSize:20,
                // property:null,   //
                // direction:null,  //
            },
            records:[],
            total:0,

            fieldTitles:[],



            columns:[]
        }
    },
    created() {
        this.getField();

    },
    methods:{
        async queryList(){
            let {code,data} = await this.$api.get(
                `/archives/server/escort/web/epidemic/prevention/details/userId/${this.row.id}`,{
                params:this.ajaxParams
            });
            if(code===200){
                this.records = data.content;
                this.total = ~~data.totalElements;

                this.$nextTick(() => {
                    this.columns = this.$refs.xTable.getColumns();
                })
            }
        },
        async getField(){
            let {code,data} = await this.$api.get(
                `/data/server/escort/web/dynamicFormField/userId/${this.row.id}/formType/EPIDEMIC_PREVENTION_REPOR`
            );
            if(code===200){
                // data.forEach(item=>item.visible = false);
                this.fieldTitles = data;
                this.queryList();
                this.boolean = true;
            }
        }
    }
}
</script>

<style scoped lang="scss">
.healthEarlyWarn{
    .top{
      display: flex;
      margin-bottom: 10px;
      justify-content: flex-end;
    }

}
</style>