<template>
    <el-dialog
        top="3vh"
        :visible.sync="showFiles"
        width="60%"
        :append-to-body="true"
        :destroy-on-close="true"
        :close-on-click-modal="false"
        @close="close"
    >
        <div slot="title" class="header-title">
            <el-row :gutter="5">
                <el-col :span="2">
                    <vxe-button @click="exportList">导出</vxe-button>
                </el-col>
                <el-col :span="22">
                    <span class="title-name" style="font-weight: bold">{{
                        info.title
                    }}</span>
                </el-col>
            </el-row>
        </div>

        <div>
            <div class="flex-row-box flex-cont-center text-center">
                <div class="w13">
                    <div class="h33 h-title">姓名</div>
                    <div class="h33 h-title">文化程度</div>
                </div>
                <div class="w13">
                    <div class="h33">{{ listInfo.realName }}</div>
                    <div class="h33">{{ listInfo.qualifications }}</div>
                </div>
                <div class="w13">
                    <div class="h33 h-title">性别</div>
                    <div class="h33">联系电话</div>
                </div>
                <div class="w13">
                    <div class="h33">{{ listInfo.sexName }}</div>
                    <div class="h33">{{ listInfo.phone }}</div>
                </div>
                <div class="w13">
                    <div class="h33 h-title">出生年月</div>
                    <!-- <div class="h33">公交驾龄</div> -->
                    <div class="h33 h-title">考勤率</div>
                </div>
                <div class="w13">
                    <div class="h33">{{ listInfo.birthTime }}</div>
                    <div class="h33">
                        {{
                            listInfo.attendanceRatio
                                ? listInfo.attendanceRatio + "%"
                                : ""
                        }}
                    </div>
                </div>
                <div class="w13 h68">
                    <el-image
                        :src="$fileUrl + listInfo.headUrl"
                        fit="contain"
                        style="width: 100%; height: 100%"
                    >
                        <div
                            slot="error"
                            class="image-slot"
                            style="transform: translateY(12px)"
                        >
                            <i
                                class="el-icon-picture-outline"
                                style="font-size: 50px; color: #999"
                            ></i>
                        </div>
                    </el-image>
                </div>
                <!--				<div class="w13 h68" v-if="!listInfo.headUrl">-->
                <!--                    <el-image src="../../../assets/user.png" style="width: 100%;height: 100%;"/>-->
                <!--                </div>-->
            </div>
            <div class="flex-row-box flex-cont-center text-center">
                <div class="w13 h68 h-title">驾驶证</div>
                <div class="w13">
                    <div class="h33 h-title">证件号码</div>
                    <div class="h33 h-title">领证日期</div>
                </div>
                <div class="w39">
                    <div class="h33">{{ listInfo.driverCardNum }}</div>
                    <div class="flex-row-box flex-cont-center">
                        <div class="h33 w33">{{ listInfo.driverCardTime }}</div>
                        <div class="h33 w33 h-title">有效日期</div>
                        <div class="h33" style="width: 34%">
                            {{ listInfo.driverCardEffectiveTime }}
                        </div>
                    </div>
                </div>
                <div class="w13">
                    <!-- <div class="h33">档案号</div> -->
                    <div class="h33 h-title">公交驾龄</div>
                    <div class="h33 h-title">档案号</div>
                </div>
                <div class="w13">
                    <div class="h33">{{ listInfo.busDriver }}</div>
                    <div class="h33">{{ listInfo.driverCardFileNum }}</div>
                    <!-- <div class="h33">{{listInfo.quasiDriverTypeName}}</div> -->
                </div>
            </div>
            <div class="flex-row-box flex-cont-center text-center">
                <div class="w13 h68 h-title">从业资格证</div>
                <div class="w13">
                    <div class="h33 h-title">证件号码</div>
                    <div class="h33 h-title">领证日期</div>
                </div>
                <div class="w70">
                    <div class="flex-row-box flex-cont-center">
                        <div class="h33" style="width: 60%">
                            {{ listInfo.employedCardNum }}
                        </div>
                        <div class="h33 h-title" style="width: 20%">
                            准驾车型
                        </div>
                        <div class="h33" style="width: 20%">
                            {{ listInfo.quasiDriverTypeName }}
                        </div>
                    </div>
                    <div class="flex-row-box flex-cont-center">
                        <div class="h33" style="width: 20%">
                            {{ listInfo.employedCardTime }}
                        </div>
                        <div class="h33 h-title" style="width: 20%">
                            有效日期
                        </div>
                        <div class="h33" style="width: 60%">
                            {{ listInfo.employedCardEffectiveTime }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex-row-box flex-cont-center text-center">
                <div class="w13 h33 h-title">安全里程</div>
                <div class="w13 h33">{{ listInfo.safetyDistance }}</div>
                <div class="w13 h33 h-title">安全年限</div>
                <div class="w13 h33">{{ listInfo.safetyYears }}</div>
                <div class="w13 h33 h-title">入职时间</div>
                <div class="w28 h33">{{ listInfo.workTime }}</div>
            </div>
            <div style="display: flex; min-height: 40px">
                <div
                    class="w13 h-title"
                    style="
                        height: auto;
                        border: 1px solid #999;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    "
                >
                    绑定车辆
                </div>
                <div
                    style="
                        width: 84%;
                        border: 1px solid #999;
                        padding: 8px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    "
                >
                    <div
                        v-if="Array.isArray(row.carVos)"
                        :title="
                            row.carVos.map((item) => item.carNum).toString()
                        "
                    >
                        {{ row.carVos.map((item) => item.carNum).toString() }}
                    </div>
                </div>
            </div>
            <div class="boxW">
                <div class="text-center pb-1" style="font-weight: bold">
                    个人履历
                </div>
                <vxe-table
                    size="mini"
                    ref="table"
                    border
                    resizable
                    :auto-resize="true"
                    :header-row-style="headerStyle"
                    :row-style="rowStyle"
                    align="center"
                    class="vxe-table-element"
                    height="140"
                    :data="curriculum"
                >
                    <vxe-table-column
                        field="startTime"
                        title="开始时间"
                        width="160"
                    />
                    <vxe-table-column field="endTime" title="结束时间" />
                    <vxe-table-column field="companyName" title="工作单位" />
                    <vxe-table-column field="positionName" title="职务" />
                </vxe-table>
            </div>

            <div style="height: 20px"></div>

            <el-tabs
                v-model="activeName"
                @tab-click="handleClick"
                tab-position="left"
                type="border-card"
                style="width: 98%"
            >
                <el-tab-pane label="安全培训" name="安全培训">
                    <SafetyTraining
                        v-if="activeName === '安全培训'"
                        :row="row"
                    />
                    <!--                    <div class="">-->
                    <!--                                                <div class="text-center pb-1" style="font-weight: bold;">安全培训</div>-->
                    <!--                        <vxe-table size="mini" ref="table" border resizable :auto-resize="true" :header-row-style="headerStyle" :row-style="rowStyle"-->
                    <!--                                   align="center" class="vxe-table-element"  :data="course">-->
                    <!--                            <vxe-table-column field="time" title="时间" width="160" />-->
                    <!--                            <vxe-table-column field="courseName" title="培训课程" />-->
                    <!--                            <vxe-table-column field="completeRatio" title="完成率(%)" />-->
                    <!--                        </vxe-table>-->
                    <!--                    </div>-->
                </el-tab-pane>

                <el-tab-pane label="纠偏学习" name="纠偏学习">
                    <RectifyStudy v-if="activeName === '纠偏学习'" :row="row" />
                </el-tab-pane>

                <el-tab-pane label="状态测评" name="状态测评">
                    <StateAppraisal
                        v-if="activeName === '状态测评'"
                        :row="row"
                    />
                </el-tab-pane>

                <el-tab-pane label="主防报警" name="主防报警">
                    <AutoAlarms :row="row" v-if="activeName === '主防报警'" />
                </el-tab-pane>
                <el-tab-pane label="健康预警" name="健康预警">
                    <healthEarlyWarn
                        :row="row"
                        v-if="activeName === '健康预警'"
                    />
                </el-tab-pane>
                <el-tab-pane label="防疫上报" name="防疫上报">
                    <EpidemicReporting
                        :row="row"
                        v-if="activeName === '防疫上报'"
                    />
                </el-tab-pane>

                <el-tab-pane label="违法违规" name="违法违规">
                    <BreakTheLaw :row="row" v-if="activeName === '违法违规'" />
                    <!--                    <div class="">-->
                    <!--                                                <div class="text-center pb-1" style="font-weight: bold;">违法违规</div>-->
                    <!--                        <vxe-table size="mini" ref="table" border resizable :auto-resize="true" :header-row-style="headerStyle" :row-style="rowStyle"-->
                    <!--                                   align="center" class="vxe-table-element"  :data="violate">-->
                    <!--                            -->
                    <!--                        </vxe-table>-->
                    <!--                    </div>-->
                </el-tab-pane>
                <el-tab-pane label="身体状况" name="身体状况">
                    <BodyState :row="row" v-if="activeName === '身体状况'" />
                    <div class="">
                        <!--                        <div class="text-center pb-1" style="font-weight: bold;">身体状况</div>-->
                        <!--                        <vxe-table size="mini" ref="table" border resizable :auto-resize="true" :header-row-style="headerStyle" :row-style="rowStyle"-->
                        <!--                                   align="center" class="vxe-table-element"  :data="physical">-->
                        <!--                          -->
                        <!--                        </vxe-table>-->
                    </div>
                </el-tab-pane>
                <el-tab-pane label="服务质量" name="服务质量">
                    <ServeQuality :row="row" v-if="activeName === '服务质量'" />
                    <!--                    <div class="">-->
                    <!--                                                <div class="text-center pb-1" style="font-weight: bold;">服务质量</div>-->
                    <!--                        <vxe-table size="mini" ref="table" border resizable :auto-resize="true" :header-row-style="headerStyle" :row-style="rowStyle"-->
                    <!--                                   align="center" class="vxe-table-element"  :data="service">-->
                    <!--                            <vxe-table-column field="serviceTime" title="时间" width="160" />-->
                    <!--                            <vxe-table-column field="serviceQualityEvent" title="服务事件" />-->
                    <!--                            <vxe-table-column field="serviceQualityAward" title="处罚结果" />-->
                    <!--                        </vxe-table>-->
                    <!--                    </div>-->
                </el-tab-pane>
                <el-tab-pane label="奖惩情况" name="奖惩情况">
                    <AwardCondition
                        :row="row"
                        v-if="activeName === '奖惩情况'"
                    />
                    <div class="">
                        <!--                        <div class="text-center pb-1" style="font-weight: bold;">奖惩状况</div>-->
                        <!--                        <vxe-table size="mini" ref="table" border resizable :auto-resize="true" :header-row-style="headerStyle" :row-style="rowStyle"-->
                        <!--                                   align="center" class="vxe-table-element"  :data="punishment">-->
                        <!--                           -->
                        <!--                        </vxe-table>-->
                    </div>
                </el-tab-pane>
                <el-tab-pane label="考勤情况" name="考勤情况">
                    <AttendanceManagement
                        :row="row"
                        v-if="activeName === '考勤情况'"
                    />
                    <!--                    <div class="">-->
                    <!--                        <div class="text-center pb-1" style="font-weight: bold;">考勤情况</div>-->
                    <!--                        <vxe-table size="mini"  ref="table" border resizable :auto-resize="true" :header-row-style="headerStyle" :row-style="rowStyle"-->
                    <!--                                   align="center" class="vxe-table-element"  :data="attendance">-->
                    <!--                            <vxe-table-column field="attendanceTime" title="考勤月份" />-->
                    <!--                            <vxe-table-column field="attendanceDays" title="出勤天数" />-->
                    <!--                            <vxe-table-column field="slickLeave" title="病假" />-->
                    <!--                            <vxe-table-column field="privateAffairLeave" title="事假" />-->
                    <!--                            <vxe-table-column field="maternityLeave" title="产假" />-->
                    <!--                            <vxe-table-column field="isAttendance" title="考勤结果" />-->
                    <!--                        </vxe-table>-->
                    <!--                    </div>-->
                </el-tab-pane>
            </el-tabs>
        </div>
    </el-dialog>
</template>

<script>
import SafetyTraining from "./components/SafetyTraining.vue";
import RectifyStudy from "./components/RectifyStudy.vue";
import StateAppraisal from "./components/StateAppraisal.vue";
import AutoAlarms from "./components/AutoAlarms.vue";
import healthEarlyWarn from "./components/healthEarlyWarn.vue";
import EpidemicReporting from "./components/EpidemicReporting.vue";
import BreakTheLaw from "./components/BreakTheLaw.vue";
import BodyState from "./components/BodyState.vue";
import ServeQuality from "./components/ServeQuality.vue";
import AwardCondition from "./components/AwardCondition.vue";
import AttendanceManagement from "./components/AttendanceManagement.vue";
import { bytesToFile } from "@/utils/handleByte";
import { rowStyle, headerStyle } from "@/utils/tableStyleJs";
export default {
    components: {
        AttendanceManagement,
        AwardCondition,
        BodyState,
        ServeQuality,
        BreakTheLaw,
        StateAppraisal,
        SafetyTraining,
        AutoAlarms,
        healthEarlyWarn,
        EpidemicReporting,
        RectifyStudy,
    },
    name: "Operate",
    props: {
        // 打开dialog的
        dialog: {
            type: Boolean, // 指定传入的类型
            // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
            default: false, // 这样可以指定默认的值
        },
        info: {
            type: Object, // 指定传入的类型
            // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
            default: () => {}, // 这样可以指定默认的值
        },
        row: {
            type: Object, // 指定传入的类型
            // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
            default: () => {}, // 这样可以指定默认的值
        },
    },
    data() {
        return {
            loading: false,
            showFiles: this.dialog,
            registration: [],
            mentality: [],
            course: [],
            physical: [],
            service: [],
            violate: [],
            punishment: [],
            curriculum: [],
            attendance: [],
            listInfo: {
                birthTime: "",
                busDriver: "",
                driverCardEffectiveTime: "",
                driverCardFileNum: "",
                driverCardNum: "",
                driverCardTime: "",
                employedCardEffectiveTime: "",
                employedCardNum: "",
                employedCardTime: "",
                employedCardTypeName: "",
                headUrl: "",
                phone: "",
                qualifications: "",
                quasiDriverTypeName: "",
                realName: "",
                safetyDistance: "",
                safetyYears: "",
                sexName: "",
                workTime: "",
            },

            activeName: "安全培训",
        };
    },
    created() {
        this.getBasic();
        // this.getCourse();
        // this.getPhysical();
        // this.getService();
        // this.getViolate();
        // this.getMentality();
        // this.listBaseRewardPunishment();
        this.getCurriculum();
        // this.getAttendance();
    },
    methods: {
        handleClick(tab, event) {
            // console.log(tab,event)
        },
        rowStyle,
        headerStyle,
        exportList() {
            this.loading = true;
            this.$axiosReq(
                "/archives/server/escort/web/archives/export/" + this.row.id,
                null,
                null,
                "get"
            )
                .then((res) => {
                    bytesToFile(res.data.bytes, res.data.fileName);
                })
                .finally((e) => {
                    this.loading = false;
                });
        },
        getBasic() {
            this.$axiosReq(
                "/archives/server/escort/web/archives/basic/" + this.row.id,
                null,
                null,
                "get"
            ).then((res) => {
                this.listInfo = res.data;
            });
        },
        // getCourse() {
        // 	this.$axiosReq('/archives/server/escort/web/archives/course/' + this.row.id, null, null, 'get').then(res => {
        // 		this.course = res.data;
        // 	})
        // },
        getPhysical() {
            this.$axiosReq(
                "/archives/server/escort/web/archives/physical/" + this.row.id,
                null,
                null,
                "get"
            ).then((res) => {
                this.physical = res.data;
            });
        },
        getService() {
            this.$axiosReq(
                "/archives/server/escort/web/archives/service/" + this.row.id,
                null,
                null,
                "get"
            ).then((res) => {
                this.service = res.data;
            });
        },
        getViolate() {
            this.$axiosReq(
                "/archives/server/escort/web/archives/violate/" + this.row.id,
                null,
                null,
                "get"
            ).then((res) => {
                this.violate = res.data;
            });
        },
        getMentality() {
            this.$axiosReq(
                "/archives/server/escort/web/archives/mentality/" + this.row.id,
                null,
                null,
                "get"
            ).then((res) => {
                this.mentality = res.data;
            });
        },
        listBaseRewardPunishment() {
            this.$axiosReq(
                "/archives/server/escort/web/rewardPunishment/listBaseRewardPunishment?userId=" +
                    this.row.id,
                null,
                null,
                "get"
            ).then((res) => {
                this.punishment = res.data;
            });
        },
        getCurriculum() {
            this.$axiosReq(
                "/archives/server/escort/web/archives/curriculum/" +
                    this.row.id,
                null,
                null,
                "get"
            ).then((res) => {
                this.curriculum = res.data;
            });
        },
        getAttendance() {
            this.$axiosReq(
                "/archives/server/escort/web/archives/attendance/" +
                    this.row.id,
                null,
                null,
                "get"
            ).then((res) => {
                this.attendance = res.data;
            });
        },
        // 关闭模态框
        close() {
            this.showFiles = false;
            this.$emit("refresh");
        },
    },
};
</script>

<style scoped>
.w13 {
    width: 14%;
}

.w28 {
    width: 28%;
}

.w33 {
    width: 33%;
}

.w39 {
    width: 42%;
}

.w70 {
    width: 70%;
}

.h33 {
    height: 39px;
    line-height: 39px;
    border: 1px solid #999;
}

.h68 {
    height: 78px;
    line-height: 78px;
    border: 1px solid #999;
}

.boxW {
    width: 98%;
    border: 1px solid #999;
    padding: 15px;
    box-sizing: border-box;
    border-radius: 10px;
    margin-top: 15px;
}

.title-name {
    font-size: 20px;
}
.h-title {
    font-weight: bold;
}
</style>
