<template>
    <div>
        <vxe-table size="mini"  height="400" ref="table" border resizable
                   :auto-resize="true"
                   :header-row-style="headerStyle"
                   :row-style="rowStyleFn"
                   align="center" class="vxe-table-element"  :data="records">
            <vxe-table-column type="seq" title="序号" width="50"/>
            <vxe-table-column field="serviceTime" title="时间" width="160" />
            <vxe-table-column field="serviceQualityEvent" title="服务事件" />
            <vxe-table-column field="serviceQualityAward" title="处罚结果" />
        </vxe-table>

        <div style="height: 20px" />
        <el-pagination background :current-page="ajaxParams.currentPage" :page-sizes="[5, 10, 15, 20, 50, 100]"
                       :page-size="ajaxParams.pageSize" layout="total, ->, sizes, prev, pager, next, jumper" :total="total"
                       @size-change="(pageSize)=>{ajaxParams.pageSize=pageSize;queryList()}"
                       @current-change="(currentPage)=>{ajaxParams.currentPage=currentPage;queryList()}" />
    </div>
</template>

<script>
export default {
    //纠偏学习
    name: "RectifyStudy",
    props: {
        row:{
            type: Object,
            default: () => {}
        }
    },
    data(){
        return{
            ajaxParams:{
                currentPage:1,
                pageSize:20,
                // property:null,   //
                // direction:null,  //
            },
            records:[],
            total:0,
        }
    },
    created() {
        this.queryList();
    },
    methods:{
        async queryList(){
            let {code,data} = await this.$api.get(
                `/archives/server/escort/web/archives/service/${this.row.id}`,
                {
                    params:this.ajaxParams
                });
            if(code===200){
                this.records = data.content;
                this.total = ~~data.totalElements;
            }
        }
    }
}
</script>

<style scoped>

</style>