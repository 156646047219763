<template>
    <div class="dashboard-container">
        <Operate
            v-if="dialog.refresh"
            :dialog.sync="dialog.show"
            :row="dialog.row"
            :info="dialog.info"
            @get-list="getList"
            @refresh="refreshDialog"
        />
        <BatchExport
            v-if="dialog.refresh2"
            :row="{ name: 1 }"
            @refresh="refreshDialog"
        />
        <BatchExportA
            v-if="dialog.refresh5"
            :row="{ name: 1 }"
            @refresh="refreshDialog"
        />
        <ViewCourse
            v-if="dialog.refresh3"
            :row="dialog.row"
            @refresh="refreshDialog"
        />
        <DispatchRole
            v-if="dialog.refreshRole"
            :dialog.sync="dialog.showRole"
            :row="dialog.row"
            :info="dialog.info"
            @refresh="refreshDialog"
        />
        <Archives
            v-if="dialog.refreshArchives"
            :dialog.sync="dialog.showArchives"
            :row="dialog.row"
            :info="dialog.info"
            @refresh="refreshDialog"
        />
        <PersonalFiles
            v-if="dialog.refreshFiles"
            :dialog.sync="dialog.showFiles"
            :row="dialog.row"
            :info="dialog.info"
            @refresh="refreshDialog"
        />
        <el-card>
            <el-row type="flex" justify="start" :gutter="0">
                <el-col>
                    <span class="text-primary text-pad-right">性别</span>
                    <el-select
                        v-model="condition.sex"
                        size="mini"
                        class="input searchInput"
                        clearable
                        @change="changeSex"
                    >
                        <el-option
                            v-for="(v, i) in sex"
                            :key="i"
                            :value="v.dictCode"
                            :label="v.dictName"
                        />
                    </el-select>
                </el-col>
                <el-col v-if="$admin">
                    <span class="text-primary text-pad-right">公司</span>
                    <el-select
                        v-model="condition.companyId"
                        class="input searchInput"
                        filterable
                        clearable
                        size="mini"
                        @change="changeCompany"
                    >
                        <el-option
                            v-for="(v, i) in company"
                            :key="i"
                            :value="v.id"
                            :label="v.companyName"
                        />
                    </el-select>
                </el-col>
                <el-col>
                    <span class="text-primary text-pad-right">部门</span>
                    <el-cascader
                        ref="dept"
                        v-model="condition.departId"
                        :options="depart"
                        class="searchInput"
                        size="mini"
                        :props="{
                            expandTrigger: 'hover',
                            value: 'id',
                            children: 'sons',
                            label: 'departName',
                            emitPath: false,
                            checkStrictly: true,
                        }"
                        filterable
                        clearable
                        @change="changeJob"
                    />
                    <!--          <el-select v-model="condition.departId" class="input searchInput" clearable size="mini" @change="changeJob">-->
                    <!--            <el-option v-for="(v,i) in depart" :key="i" :value="v.id" :label="v.departName" />-->
                    <!--          </el-select>-->
                </el-col>
                <el-col>
                    <span class="text-primary text-pad-right">岗位</span>
                    <el-select
                        v-model="condition.positionId"
                        class="input searchInput"
                        clearable
                        size="mini"
                        @change="changeJob"
                    >
                        <el-option
                            v-for="(v, i) in position"
                            :key="i"
                            :value="v.id"
                            :label="v.positionName"
                        />
                    </el-select>
                </el-col>
                <el-col>
                    <el-input
                        v-model="condition.searchName"
                        class="input searchInput"
                        size="mini"
                        suffix-icon="el-icon-search"
                        placeholder="搜索"
                        @input="searchInput"
                    />
                </el-col>
            </el-row>
        </el-card>
        <el-card style="margin-top: 20px">
            <el-row>
                <vxe-toolbar>
                    <template v-slot:buttons>
                        <el-row type="flex" justify="space-between">
                            <el-col>
                                <el-button
                                    v-if="
                                        $hasBtn('sys:permissions:org:user:add')
                                    "
                                    type="primary"
                                    size="mini"
                                    @click="
                                        openDialog({
                                            title: '添加人员',
                                            addOr: 'add',
                                        })
                                    "
                                    >添加人员</el-button
                                >
                                <el-button
                                    v-if="
                                        $hasBtn(
                                            'sys:permissions:org:user:batch:Import'
                                        )
                                    "
                                    type="warning"
                                    size="mini"
                                    @click="openExport"
                                    >批量导入</el-button
                                >
                                <el-button
                                    v-if="
                                        $hasBtn(
                                            'sys:permissions:org:user:batch:delete'
                                        )
                                    "
                                    type="danger"
                                    size="mini"
                                    @click="removeAll"
                                    >批量删除</el-button
                                >
                                <el-button
                                    v-if="
                                        $hasBtn(
                                            'sys:permissions:org:user:batch:add:information'
                                        )
                                    "
                                    type="warning"
                                    size="mini"
                                    @click="openExportA"
                                    >批量添加档案</el-button
                                >
                            </el-col>
                            <el-col :span="6">
                                <vxe-button
                                    v-if="
                                        $hasBtn(
                                            'sys:permissions:org:user:export'
                                        )
                                    "
                                    @click="exportList"
                                    >导出驾驶档案</vxe-button
                                >
                                <vxe-button @click="exportList1"
                                    >导出用户表</vxe-button
                                >
                                <vxe-button @click="getListNow"
                                    >刷新</vxe-button
                                >
                            </el-col>
                        </el-row>
                    </template>
                </vxe-toolbar>
                <vxe-table
                    ref="table"
                    border
                    resizable
                    :auto-resize="true"
                    :header-row-style="headerStyle"
                    :row-style="rowStyle"
                    align="center"
                    class="vxe-table-element"
                    height="620"
                    row-id="company_id"
                    :sort-config="{ remote: true }"
                    :filter-config="{ remote: true }"
                    :checkbox-config="{ reserve: true }"
                    :data="table.list"
                    :loading="loading"
                    @checkbox-all="handleSelectionChange"
                    @checkbox-change="handleSelectionChange"
                >
                    <vxe-table-column type="checkbox" width="60" fixed="left" />
                    <vxe-table-column type="seq" width="60" />
                    <vxe-table-column
                        field="realName"
                        title="真实姓名"
                        show-overflow="title"
                    />
                    <vxe-table-column
                        field="username"
                        title="用户账号"
                        show-overflow="title"
                    />
                    <vxe-table-column title="人脸信息" width="130">
                        <template v-slot="{ row, rowIndex }">
                            <el-popover
                                v-if="row.headUrl"
                                placement="right"
                                width="200"
                                trigger="hover"
                            >
                                <img
                                    :src="$fileUrl + row.headUrl"
                                    style="width: 500px; height: 500px"
                                />
                                <img
                                    slot="reference"
                                    :src="$fileUrl + row.headUrl"
                                    style="width: 100px; height: 100px"
                                />
                            </el-popover>
                            <img
                                v-else
                                :src="require('@/assets/user.png')"
                                style="width: 100px; height: 100px"
                            />
                        </template>
                    </vxe-table-column>
                    <vxe-table-column field="sexName" title="性别" />
                    <vxe-table-column
                        field="phone"
                        title="手机号"
                        show-overflow="title"
                    />
                    <vxe-table-column title="绑定车辆" show-overflow="title">
                        <template v-slot="{ row }">
                            <div v-if="Array.isArray(row.carVos)">
                                {{
                                    row.carVos
                                        .map((item) => item.carNum)
                                        .toString()
                                }}
                            </div>
                        </template>
                    </vxe-table-column>
                    <vxe-table-column
                        field="idCard"
                        title="身份证"
                        show-overflow="title"
                    />
                    <vxe-table-column
                        field="positionName"
                        title="岗位"
                        show-overflow="title"
                    />
                    <vxe-table-column
                        field="companyName"
                        title="公司名称"
                        show-overflow="title"
                    />
                    <vxe-table-column
                        field="departName"
                        title="所属部门"
                        show-overflow="title"
                    />
                    <vxe-table-column
                        field="labels"
                        title="标签"
                        show-overflow="title"
                    />
                    <vxe-table-column title="公司管理员">
                        <template v-slot="{ row, rowIndex }">
                            <span v-if="row.isCompanyAdmin == 0">是</span>
                            <span v-else>否</span>
                        </template>
                    </vxe-table-column>
                    <vxe-table-column title="是否离职">
                        <template v-slot="{ row }">
                            <span v-if="row.isJob == 0" class="text-success"
                                >在职</span
                            >
                            <span v-else class="text-danger">离职</span>
                        </template>
                    </vxe-table-column>
                    <vxe-table-column title="个人档案">
                        <template v-slot="{ row }">
                            <span
                                style="color: #1890ff; cursor: pointer"
                                @click="
                                    clickFiles(
                                        { title: '个人档案', addOr: 'info' },
                                        row
                                    )
                                "
                                >点击查看详情</span
                            >
                        </template>
                    </vxe-table-column>
                    <vxe-table-column
                        v-if="$hasBtn('sys:permissions:org:user:status')"
                        title="是否启用"
                    >
                        <template v-slot="{ row }">
                            <el-switch
                                ref="switch"
                                v-model="row.status"
                                active-color="#13ce66"
                                inactive-color="#ff4949"
                                :active-value="0"
                                :inactive-value="1"
                                @change="
                                    (v) => {
                                        changeStatus(v, row);
                                    }
                                "
                            />
                        </template>
                    </vxe-table-column>
                    <vxe-table-column title="操作">
                        <template v-slot="{ row }">
                            <el-dropdown size="small" type="primary">
                                <el-button size="small" type="primary">
                                    操作<i
                                        class="el-icon-arrow-down el-icon--right"
                                    />
                                </el-button>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item
                                        v-if="
                                            $hasBtn(
                                                'sys:permissions:org:user:edit'
                                            )
                                        "
                                        icon="el-icon-edit"
                                        @click.native="
                                            openDialog(
                                                {
                                                    title: '修改人员',
                                                    addOr: 'upd',
                                                },
                                                row
                                            )
                                        "
                                        >修改</el-dropdown-item
                                    >
                                    <el-dropdown-item
                                        v-if="
                                            $hasBtn(
                                                'sys:permissions:org:user:user'
                                            )
                                        "
                                        icon="el-icon-edit"
                                        @click.native="
                                            openDialogRole(
                                                {
                                                    title: '配置角色',
                                                    addOr: 'role',
                                                },
                                                row
                                            )
                                        "
                                        >配置角色</el-dropdown-item
                                    >
                                    <el-dropdown-item
                                        v-if="
                                            $hasBtn(
                                                'sys:permissions:org:user:delete'
                                            )
                                        "
                                        icon="el-icon-delete"
                                        @click.native="
                                            remove({ del: 'single' }, row)
                                        "
                                        >删除</el-dropdown-item
                                    >
                                    <el-dropdown-item
                                        v-if="
                                            $hasBtn(
                                                'sys:permissions:org:user:induction'
                                            )
                                        "
                                        icon="el-icon-delete"
                                        @click.native="
                                            remove({ del: 'leave' }, row)
                                        "
                                        >{{
                                            row.isJob === 0 ? "离职" : "入职"
                                        }}</el-dropdown-item
                                    >
                                    <!--									<el-dropdown-item icon="el-icon-zoom-in" v-if="-->
                                    <!--										    $hasBtn(-->
                                    <!--										        'sys:permissions:org:user:look'-->
                                    <!--										    )-->
                                    <!--										"-->
                                    <!--									 @click.native="-->
                                    <!--                                            openView({ title: '查看课程' }, row)-->
                                    <!--                                        ">查看课程</el-dropdown-item>-->
                                    <el-dropdown-item
                                        icon="el-icon-s-custom"
                                        v-if="
                                            $hasBtn(
                                                'sys:permissions:org:user:photo'
                                            )
                                        "
                                        @click.native="
                                            resetUser(
                                                {
                                                    title: '重置人脸',
                                                    del: 'face',
                                                },
                                                row
                                            )
                                        "
                                        >重置人脸</el-dropdown-item
                                    >
                                    <el-dropdown-item
                                        v-if="
                                            $hasBtn(
                                                'sys:permissions:org:user:password'
                                            )
                                        "
                                        icon="el-icon-close"
                                        @click.native="
                                            resetUser(
                                                {
                                                    title: '重置密码',
                                                    del: 'pwd',
                                                },
                                                row
                                            )
                                        "
                                        >重置密码</el-dropdown-item
                                    >
                                    <!-- <el-dropdown-item
                                        icon="el-icon-edit"
                                        @click.native="
                                            openArchives(
                                                {
                                                    title: '添加驾驶信息',
                                                    addOr: 'add',
                                                },
                                                row
                                            )
                                        "
                                        >添加驾驶信息</el-dropdown-item
                                    > -->
                                    <el-dropdown-item
                                        icon="el-icon-edit"
                                        v-if="
                                            $hasBtn(
                                                'sys:permissions:org:user:edit:information'
                                            )
                                        "
                                        @click.native="
                                            openArchives(
                                                {
                                                    title: '编辑驾驶信息',
                                                    addOr: 'upd',
                                                },
                                                row
                                            )
                                        "
                                        >编辑驾驶信息</el-dropdown-item
                                    >
                                </el-dropdown-menu>
                            </el-dropdown>
                        </template>
                    </vxe-table-column>
                </vxe-table>
                <div style="height: 20px" />
                <el-pagination
                    background
                    :current-page="table.currentPage"
                    :page-sizes="[5, 10, 15, 20, 50, 100]"
                    :page-size="table.pageSize"
                    layout="total, ->, sizes, prev, pager, next, jumper"
                    :total="table.total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                />
            </el-row>
        </el-card>
    </div>
</template>

<script>
import { bytesToFile } from "@/utils/handleByte";
import { rowStyle, headerStyle } from "@/utils/tableStyleJs";
import Operate from "./Operate";
import Archives from "./Archives";
import BatchExport from "./BatchExport";
import BatchExportA from "./BatchExportA";
import ViewCourse from "./ViewCourse";
import DispatchRole from "./DispatchRole";
import PersonalFiles from "./PersonalFiles";
import {
    user,
    sysUserResetPwd,
    userLeave,
    company,
    position,
    getDictParentCode,
    depart,
    userStatus,
    deleteAll,
    resetHead,
    dropUserPosition,
} from "@/api";
export default {
    name: "Index",
    components: {
        Archives,
        Operate,
        BatchExport,
        ViewCourse,
        DispatchRole,
        PersonalFiles,
        BatchExportA,
    },
    data() {
        return {
            loading: false,
            condition: {
                sex: "",
                companyId: this.$admin ? null : this.$companyId,
                departId: null,
                positionId: null,
                searchName: "",
            },
            table: {
                list: [],
                total: 0,
                currentPage: 1,
                pageSize: 10,
                sortName: "",
                sortBy: "",
            },
            dialog: {
                row: Object,
                info: {},
                refresh: false, // 每次刷新DOM
                refresh2: false, // 每次刷新DOM
                refresh3: false, // 每次刷新DOM
                refreshRole: false, // 每次刷新DOM
                show: false,
                show2: false,
                show3: false,
                showRole: false,
                refreshArchives: false,
                showArchives: false,
                refreshFiles: false,
                showFiles: false,
                refresh5: false,
                show5: false,
            },
            sex: [],
            company: [],
            position: [],
            depart: [],
            loginInfo: {
                role_g_id: 3,
            },
            userIds: [],
            wsUserId: "",
            wspath: "wss://www.zdwy.online/ws",
            // wspath: "ws://192.168.88.59:8088/ws",
            // wspath: 'ws://192.168.88.242:18088/ws',
        };
    },
    created() {
        this.wsUserId = JSON.parse(sessionStorage.UserDetail).id;
        this.getList();
        this.getDictParentCode();
        this.getCompany();
        this.getWebSocket();
        // this.getPosition()
    },
    mounted() {
        if (!this.$admin) {
            this.getDepart();
            this.getPosition();
        }
    },
    methods: {
        rowStyle,
        headerStyle,
        getDictParentCode() {
            this.$axiosReq(getDictParentCode + "SEX", null, null, "get").then(
                (res) => {
                    this.sex = res.data;
                }
            );
        },
        getCompany() {
            this.$axiosReq(
                company,
                null,
                {
                    pageSize: 10000,
                },
                "get"
            ).then((res) => {
                this.company = res.data.content;
            });
        },
        getDepart() {
            this.$axiosReq(
                depart,
                null,
                {
                    companyId: this.condition.companyId,
                    type: 1,
                },
                "get"
            ).then((res) => {
                this.depart = res.data;
            });
        },
        getPosition() {
            // this.$axiosReq(position, null, {
            //   companyIds: this.condition.companyId,
            //   pageSize: 10000
            // }, 'get').then(res => {
            //   this.position = res.data.content
            // })
            this.$axiosReq(
                dropUserPosition,
                null,
                {
                    companyId: this.condition.companyId,
                },
                "get"
            ).then((res) => {
                this.position = res.data;
            });
        },
        // 打开dialog
        openDialog(info, row) {
            this.dialog.refresh = true;
            this.dialog.show = true;
            this.dialog.info = info;
            this.dialog.row = row;
        },
        openDialogRole(info, row) {
            this.dialog.refreshRole = true;
            this.dialog.showRole = true;
            this.dialog.info = info;
            this.dialog.row = row;
        },
        openArchives(info, row) {
            this.dialog.refreshArchives = true;
            this.dialog.showArchives = true;
            this.dialog.info = info;
            this.dialog.row = row;
        },
        clickFiles(info, row) {
            // if (row.isDriverinfo == 1) {
            // 	this.$message.error("请添加个人档案信息");
            // 	return false;
            // }
            this.dialog.refreshFiles = true;
            this.dialog.showFiles = true;
            this.dialog.info = info;
            this.dialog.row = row;
        },
        // 打开批量导入
        openExport() {
            this.dialog.refresh2 = true;
            this.dialog.show2 = true;
        },
        openExportA() {
            this.dialog.refresh5 = true;
            this.dialog.show5 = true;
        },
        openView(info, row) {
            this.dialog.refresh3 = true;
            this.dialog.show3 = true;
            this.dialog.info = info;
            this.dialog.row = row;
        },
        searchInput(v) {
            this.table.currentPage = 1;
            this.getList();
        },
        changeSex() {
            this.table.currentPage = 1;
            this.getList();
        },
        changeCompany(v) {
            this.table.currentPage = 1;
            this.condition.departId = "";
            this.condition.positionId = "";
            if (v) {
                this.getDepart(v);
                this.getPosition(v);
                // this.$axiosReq(position, null, {
                //   companyIds: this.condition.companyId,
                //   pageSize: 10000
                // }, 'get').then(res => {
                //   this.position = res.data.content
                // })
            } else {
                this.depart = [];
                this.position = [];
                // this.condition.departId = ''
                // this.condition.positionId = ''
            }
            this.getList();
        },
        changeJob() {
            this.table.currentPage = 1;
            this.getList();
        },
        changeStatus(v, row) {
            this.$axiosReq(userStatus + row.id, null, null, "put").then(
                (res) => {
                    this.getList();
                    this.$message.success(res.msg);
                }
            );
        },
        getList() {
            this.loading = true;
            this.$axiosReq(
                user,
                null,
                {
                    departId: this.condition.departId,
                    positionId: this.condition.positionId,
                    companyId: this.condition.companyId,
                    searchName: this.condition.searchName,
                    sex: this.condition.sex,
                    currentPage: this.table.currentPage,
                    pageSize: this.table.pageSize,
                },
                "get"
            )
                .then((res) => {
                    this.table.list = res.data.content;
                    this.table.total = Number(res.data.totalElements);
                })
                .finally((res) => {
                    this.loading = false;
                });
        },
        remove(info, row) {
            if (info.del === "batch") {
                const arr = this.$refs.table.getCheckboxRecords();
                const arr1 = this.$refs.table.getCheckboxReserveRecords();
                const batchDel = arr.concat(arr1);
                // for (const i of batchDel) {}
            } else if (info.del === "leave") {
                let msg = "";
                if (row.isJob === 0) {
                    msg = "确认离职吗！！！";
                } else {
                    msg = "确认入职吗！！！";
                }
                this.$confirm(msg, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        this.$axiosReq(
                            userLeave + row.id,
                            null,
                            null,
                            "put"
                        ).then((res) => {
                            this.$message.success(res.msg);
                            this.getList(this.condition.company);
                        });
                    })
                    .catch(() => {
                        this.$message.info("取消了离职！！！");
                    });
            } else {
                this.$confirm("确认删除吗！！！", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        this.$axiosReq(
                            user + row.id,
                            null,
                            null,
                            "delete"
                        ).then((res) => {
                            this.$message.success(res.msg);
                            this.getList(this.condition.company);
                        });
                    })
                    .catch(() => {
                        this.$message.info("取消了删除！！！");
                    });
            }
        },
        getListNow() {
            this.userIds = [];
            this.getList();
        },
        removeAll() {
            var that_ = this;
            if (that_.userIds.length < 1) {
                this.$message.error("请勾选需要删除的选项");
                return false;
            }
            this.$confirm("确认批量删除吗！！！", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.$axiosReq(
                        deleteAll + "?ids=" + that_.userIds,
                        null,
                        null,
                        "delete"
                    ).then((res) => {
                        //console.log(JSON.stringify(res))
                        that_.$message.success("批量删除成功");
                        that_.userIds = [];
                        that_.getList();
                    });
                })
                .catch(() => {
                    that_.$message.info("取消了批量删除！！！");
                });
        },
        resetUser(info, row) {
            if (info.del === "face") {
                this.$confirm("确认重置人脸！！！", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        this.$axiosReq(
                            resetHead + row.id,
                            null,
                            null,
                            "put"
                        ).then((res) => {
                            this.$message.success("重置人脸成功");
                            this.getList();
                        });
                    })
                    .catch(() => {
                        this.$message.info("取消了重置人脸！！！");
                    });
            } else {
                this.$confirm("确认重置密码吗！！！", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        this.$axiosReq(
                            sysUserResetPwd + row.id,
                            null,
                            null,
                            "get"
                        ).then((res) => {
                            this.$message.success(
                                res.msg + "，密码：zd@123456"
                            );
                            this.getList(this.condition.company);
                        });
                    })
                    .catch(() => {
                        this.$message.info("取消了重置密码！！！");
                    });
            }
        },
        handleSizeChange(pageSize) {
            this.table.pageSize = pageSize;
            this.table.currentPage = 1;
            this.userIds = [];
            this.table.list = [];
            this.getList();
        },
        handleCurrentChange(currentPage) {
            this.table.currentPage = currentPage;
            this.userIds = [];
            this.table.list = [];
            this.getList();
        },
        refreshDialog() {
            this.dialog.refresh = false;
            this.dialog.refresh2 = false;
            this.dialog.refresh3 = false;
            this.dialog.refreshRole = false;
            this.dialog.refreshArchives = false;
            this.dialog.refreshFiles = false;
            this.dialog.refresh5 = false;
            this.getList();
        },
        handleSelectionChange(val) {
            const data = val.records;
            this.userIds = [];
            for (let i = 0; i < data.length; i++) {
                this.userIds.push(data[i].id);
            }
        },
        getWebSocket() {
            var that_ = this;
            if (typeof WebSocket === "undefined") {
                this.$message.warning("您的浏览器不支持socket");
            } else {
                // 实例化socket
                this.socket = new WebSocket(that_.wspath);
                // 监听socket连接
                this.socket.onopen = this.open;
                // 监听socket错误信息
                this.socket.onerror = this.error;
                // 监听socket消息
                this.socket.onmessage = this.getMessage;
                // 监听socket关闭
                // this.socket.onclose = this.close
            }
        },
        open() {
            this.send();
        },
        error() {
            console.log("WebSocket连接错误");
        },
        getMessage(msg) {
            var dataList = JSON.parse(msg.data);
            console.log(dataList);
            if (dataList.data && dataList.data.type == 1) {
                this.$message.success(dataList.data.content);
            }
        },
        send() {
            var params = {
                userId: this.wsUserId,
                type: "web",
            };
            this.socket.send(JSON.stringify(params));
            console.log("WebSocket连接");
        },
        close() {
            console.log("socket已经关闭");
            this.getWebSocket();
        },
        exportList() {
            this.loading = true;
            if (this.userIds.length < 1) {
                this.$message.error("请选择导出对象");
                return false;
            }
            this.$axiosReq(
                "/archives/server/escort/web/archives/basic/export",
                this.userIds,
                null,
                "post"
            )
                .then((res) => {
                    // this.userIds = [];
                    bytesToFile(res.data.bytes, res.data.fileName);
                })
                .finally((e) => {
                    this.loading = false;
                });
        },
        exportList1() {
            if (this.userIds.length < 1) {
                this.$message.error("请选择导出对象");
                return false;
            }
            this.loading = true;
            this.$axiosReq(
                "/oauth/server/escort/web/user/export",
                {
                    userIds: this.userIds,
                },
                null,
                "post"
            )
                .then((res) => {
                    this.userIds = [];
                    bytesToFile(res.data.bytes, res.data.fileName);
                })
                .finally((e) => {
                    this.loading = false;
                });
        },
    },
};
</script>

<style scoped></style>
