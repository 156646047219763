<template>
	<el-dialog :visible.sync="showArchives" width="43%" :append-to-body="true" top="2vh" :destroy-on-close="true"
	 :close-on-click-modal="false" @close="close">
		<div slot="title" class="header-title">
			<el-row :gutter="5">
				<el-col :span="24">
					<span class="title-name">{{ info.title }}</span>
				</el-col>
			</el-row>
		</div>
		<el-row :gutter="0">
			<el-col :span="24">
				<el-form size="small" ref="ruleForm" label-width="160px" class="allForm">
					<el-form-item label="驾驶证号码" :required="true">
						<el-input v-model="form.driverCardNum" class="formInput" placeholder="请输入驾驶证号码" />
					</el-form-item>
					<el-form-item label="驾驶证档案号">
						<el-input v-model="form.driverCardFileNum" class="formInput" placeholder="请输入驾驶证档案号" />
					</el-form-item>
					<el-form-item label="驾驶证领证日期" class="formInput">
						<el-date-picker v-model="form.driverCardTime" type="date" value-format="yyyy-MM-dd" placeholder="选择日期">
						</el-date-picker>
					</el-form-item>
					<el-form-item label="驾驶证有效日期" class="formInput">
						<el-date-picker v-model="form.driverCardEffectiveTime" type="date" value-format="yyyy-MM-dd"
						 placeholder="选择日期" @change="dateChange">
						</el-date-picker>
						<el-checkbox v-model="checked" style="margin-left: 20px;" @change="changeChecked">长期有效</el-checkbox>
					</el-form-item>
					<el-form-item label="准驾车型" class="formInput">
						<el-select v-model="form.quasiDriverType" class="w-100">
							<el-option v-for="(v,i) in quasiDriverTypeList" :key="v.id" :value="v.dictCode" :label="v.dictName" />
						</el-select>
					</el-form-item>
					<el-form-item label="从业资格证号码">
						<el-input v-model="form.employedCardNum" class="formInput" placeholder="请输入从业资格证号码" />
					</el-form-item>
					<!-- <el-form-item label="从业资格类型" class="formInput">
						<el-select v-model="form.employedCardType" class="w-100">
							<el-option v-for="(v,i) in employedCardTypeList" :key="v.id" :value="v.dictCode" :label="v.dictName" />
						</el-select>
					</el-form-item> -->
					<el-form-item label="从业资格证领证日期" class="formInput">
						<el-date-picker v-model="form.employedCardTime" type="date" value-format="yyyy-MM-dd" placeholder="选择日期">
						</el-date-picker>
					</el-form-item>
					<el-form-item label="从业资格有效日期" class="formInput">
						<el-date-picker v-model="form.employedCardEffectiveTime" type="date" value-format="yyyy-MM-dd"
						 placeholder="选择日期" @change="dateChange1">
						</el-date-picker>
						<el-checkbox v-model="checked1" style="margin-left: 20px;" @change="changeChecked1">长期有效</el-checkbox>
					</el-form-item>
					<el-form-item label="公交驾龄">
						<el-input v-model="form.busDriver" class="formInput" type="number" placeholder="请输入公交驾龄" />
					</el-form-item>
					<el-form-item label="安全里程">
						<el-input v-model="form.safetyDistance" class="formInput" type="number" placeholder="请输入安全里程" />
					</el-form-item>
					<el-form-item label="安全年限">
						<el-input v-model="form.safetyYears" class="formInput" type="number" placeholder="请输入安全年限" />
					</el-form-item>
					<el-form-item label="初始星级" class="formInput">
						<el-select v-model="form.driverLever" class="w-100">
							<el-option v-for="(v,i) in driverLeverList" :key="v.id" :value="v.dictCode" :label="v.dictName" />
						</el-select>
					</el-form-item>
				</el-form>
				<div class="boxW">
					<div class="pb-1" style="font-weight: bold;">个人履历</div>
					<vxe-table ref="table" border resizable :auto-resize="true" :header-row-style="headerStyle" :row-style="rowStyle"
					 align="center" class="vxe-table-element" :data="form.curriculumVitaeSos">
						<vxe-table-column title="开始时间" width="167">
							<template v-slot="{ row }">
								<el-date-picker size="mini" v-if="row.isShow == 2" style="width: 147px;" v-model="row.startTime" type="date" value-format="yyyy-MM-dd"
								 placeholder="选择日期">
								</el-date-picker>
								<el-date-picker size="mini" v-if="row.isShow == 1" style="width: 147px;" v-model="row.startTime" type="date" value-format="yyyy-MM-dd"
								 placeholder="选择日期" :disabled="true">
								</el-date-picker>
							</template>
						</vxe-table-column>
						<vxe-table-column title="结束时间" width="167">
							<template v-slot="{ row }">
								<el-date-picker size="mini" v-if="row.isShow == 2" style="width: 147px;" v-model="row.endTime" type="date" value-format="yyyy-MM-dd"
								 placeholder="选择日期">
								</el-date-picker>
								<el-date-picker size="mini" v-if="row.isShow == 1" style="width: 147px;" v-model="row.endTime" type="date" value-format="yyyy-MM-dd"
								 placeholder="选择日期" :disabled="true">
								</el-date-picker>
							</template>
						</vxe-table-column>
						<vxe-table-column title="工作单位">
							<template v-slot="{ row }">
								<el-input size="mini" v-if="row.isShow == 2" v-model="row.companyName" class="formInput" type="text" placeholder="请输入工作单位" />
								<el-input size="mini" v-if="row.isShow == 1" v-model="row.companyName" :readonly="true" class="formInput" type="text" placeholder="请输入工作单位" />
							</template>
						</vxe-table-column>
						<vxe-table-column title="职务">
							<template v-slot="{ row }">
								<el-input size="mini" v-if="row.isShow == 2" v-model="row.positionName" class="formInput" type="text" placeholder="请输入职务" />
								<el-input size="mini" v-if="row.isShow == 1" v-model="row.positionName" :readonly="true" class="formInput" type="text" placeholder="请输入职务" />
							</template>
						</vxe-table-column>
						<vxe-table-column width="40">
							<template v-slot="{ row }">
								<i v-if="row.isShow == 2" class="el-icon-error" style="color: #FF0000;font-size: 18px;" @click="deleteOne(row)"></i>
							</template>
						</vxe-table-column>
					</vxe-table>
					<div class="text-center" style="color: #1890FF;padding: 10px;" @click="addCurriculumVitaeSo">+新增信息</div>
				</div>
			</el-col>
		</el-row>
		<div slot="footer" class="dialog-footer" style="text-align: center;">
			<el-button type="primary" @click="commit">提交</el-button>
			<el-button type="info" @click="close">取消</el-button>
		</div>
	</el-dialog>
</template>

<script>
	import {
		rowStyle,
		headerStyle
	} from "@/utils/tableStyleJs";
	export default {
		name: 'Operate',
		props: {
			// 打开dialog的
			dialog: {
				type: Boolean, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: false // 这样可以指定默认的值
			},
			info: {
				type: Object, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: () => {} // 这样可以指定默认的值
			},
			row: {
				type: Object, // 指定传入的类型
				// type 也可以是一个自定义构造器函数，使用 instanceof 检测。
				default: () => {} // 这样可以指定默认的值
			}
		},
		data() {
			return {
				showArchives: this.dialog,
				checked: false,
				checked1: false,
				form: {
					"busDriver": 0,
					"driverCardEffectiveTime": "",
					"driverCardFileNum": "",
					"driverCardNum": "",
					"driverCardTime": "",
					"driverLever": '',
					"employedCardEffectiveTime": "",
					"employedCardNum": "",
					"employedCardTime": "",
					"quasiDriverType": "",
					"safetyDistance": 0,
					"safetyYears": 0,
					"userId": "",
					"curriculumVitaeSos": [{
						"companyName": '',
						"endTime": '',
						"id": '',
						"positionName": '1',
						"startTime": '1',
						"isShow": '2'
					}]
				},
				driverLeverList: [],
				employedCardTypeList: [],
				quasiDriverTypeList: [],
				archivesId: ''
			}
		},
		created() {
			this.form.userId = this.row.id;
			this.getParentCode('TYPE_OF_QUALIFICATION');
			this.getParentCode('QUASI_DRIVING_MODEL');
			this.getParentCode('STAR');
		},
		mounted() {
			this.$nextTick(() => {
				this.upd()
			})
		},
		methods: {
			rowStyle,
			headerStyle,
			upd() {
				if (this.info.addOr === 'upd') {
					this.$axiosReq('/archives/server/escort/web/archives/' + this.row.id, null, null, 'get').then(res => {
						// console.log(JSON.stringify(res))
						this.form = res.data;
						this.archivesId = res.data.id ? res.data.id : '';
						if (!this.form.userId) {
							this.form.userId = this.row.id;
						}
						if (!res.data.curriculumVitaeSos || res.data.curriculumVitaeSos.length < 1) {
							this.form.curriculumVitaeSos = [{
								"companyName": '',
								"endTime": '',
								"id": '',
								"positionName": '',
								"startTime": '',
								"isShow": '2'
							}];
						} else {
							this.form.curriculumVitaeSos.forEach(item => {
								item.isShow = 1;
								if (item.startTime) {
									item.startTime = item.startTime
								}
								if (item.endTime) {
									item.endTime = item.endTime
								}
							})
						}
						if (res.data.employedCardEffectiveTime == '2099-12-30') {
							this.checked1 = true
						}
						if (res.data.driverCardEffectiveTime == '2099-12-30') {
							this.checked = true
						}
					})
				}
			},
			addCurriculumVitaeSo() {
				this.form.curriculumVitaeSos.push({
					"companyName": '',
					"endTime": '',
					"id": '',
					"positionName": '',
					"startTime": '',
					"isShow": '2'
				})
				this.$refs.table.reloadData(this.form.curriculumVitaeSos)
			},
			deleteOne(obj){
				let that_ = this;
				let list = [];
				this.form.curriculumVitaeSos.forEach(item => {
					if (obj.companyName != item.companyName) {
						list.push(item)
					}
				})
				this.form.curriculumVitaeSos = list;
			},
			changeChecked(v){
				if (v) {
					this.form.driverCardEffectiveTime = '2099-12-30';
				}
			},
			changeChecked1(v){
				if (v) {
					this.form.employedCardEffectiveTime = '2099-12-30';
				}
			},
			dateChange(){
				this.checked = false;
			},
			dateChange1(){
				this.checked1 = false;
			},
			getParentCode(id) {
				this.$axiosReq('/data/server/escort/web/dict/parentCode/' + id, null, null, 'get').then(res => {
					if (id == 'TYPE_OF_QUALIFICATION') {
						this.employedCardTypeList = res.data;
					} else if (id == 'QUASI_DRIVING_MODEL') {
						this.quasiDriverTypeList = res.data;
					} else if (id == 'STAR') {
						this.driverLeverList = res.data;
					}
				})
			},
			// 提交添加
			commit() {
				if (!this.form.driverCardNum) {
					this.$message.error('请填写驾驶证号码！')
					return
				}
				this.$axiosReq('/archives/server/escort/web/archives?id=' + this.archivesId, this.form, null, 'post').then(res => {
					this.$message.success(res.msg)
					this.close()
				})
			},
			// 关闭模态框
			close() {
				this.showArchives = false
				this.$emit('refresh')
			}
		}
	}
</script>

<style scoped>
.el-form{
    padding-right: 25px;
}
	.boxW {
		width: 98%;
		/* border: 1px solid #999; */
		padding: 10px 10px 5px;
		box-sizing: border-box;
		border-radius: 10px;
		margin-top: 15px;
	}

	.title-name {
		font-size: 20px;
	}
</style>
